// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["XbgVtLY35", "DJs3MK0D2"];

const serializationHash = "framer-CcMsa"

const variantClassNames = {DJs3MK0D2: "framer-v-z6n564", XbgVtLY35: "framer-v-v789x9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "XbgVtLY35", Open: "DJs3MK0D2"}

const getProps = ({color, height, hover, id, text, width, ...props}) => { return {...props, sUniGUsLS: text ?? props.sUniGUsLS ?? "Link", T7R6_DfGi: hover ?? props.T7R6_DfGi, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XbgVtLY35", YYSdt3utz: color ?? props.YYSdt3utz ?? "var(--token-7288c754-42a3-4bcb-928f-d7e01bf62ad6, rgb(0, 0, 0))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string;text?: string;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, YYSdt3utz, sUniGUsLS, T7R6_DfGi, YYSdt3utziOhk89Wf5, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "XbgVtLY35", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1gw3shh = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (T7R6_DfGi) {const res = await T7R6_DfGi(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-v789x9", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"XbgVtLY35"} onMouseEnter={onMouseEnter1gw3shh} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({DJs3MK0D2: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-YYSdt3utz-iOhk89Wf5))"}}>Link</motion.p></React.Fragment>} className={"framer-ryib0d"} data-framer-name={"Link"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"OlX511MUl"} style={{"--extracted-r6o4lv": "var(--variable-reference-YYSdt3utz-iOhk89Wf5)", "--framer-paragraph-spacing": "0px", "--variable-reference-YYSdt3utz-iOhk89Wf5": YYSdt3utziOhk89Wf5}} text={sUniGUsLS} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-1ookfgq-container"} layoutDependency={layoutDependency} layoutId={"Ag8h6YMzq-container"} style={{rotate: 0}} variants={{DJs3MK0D2: {rotate: 180}}}><Phosphor color={YYSdt3utz} height={"100%"} iconSearch={"House"} iconSelection={"CaretDown"} id={"Ag8h6YMzq"} layoutId={"Ag8h6YMzq"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CcMsa.framer-1w9hnux, .framer-CcMsa .framer-1w9hnux { display: block; }", ".framer-CcMsa.framer-v789x9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-CcMsa .framer-ryib0d { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", ".framer-CcMsa .framer-1ookfgq-container { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CcMsa.framer-v789x9 { gap: 0px; } .framer-CcMsa.framer-v789x9 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-CcMsa.framer-v789x9 > :first-child { margin-left: 0px; } .framer-CcMsa.framer-v789x9 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 55
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"DJs3MK0D2":{"layout":["auto","auto"]}}}
 * @framerVariables {"YYSdt3utz":"color","sUniGUsLS":"text","T7R6_DfGi":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIp0CJH8r8: React.ComponentType<Props> = withCSS(Component, css, "framer-CcMsa") as typeof Component;
export default FramerIp0CJH8r8;

FramerIp0CJH8r8.displayName = "Nav / Nav Menu";

FramerIp0CJH8r8.defaultProps = {height: 24, width: 55};

addPropertyControls(FramerIp0CJH8r8, {variant: {options: ["XbgVtLY35", "DJs3MK0D2"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, YYSdt3utz: {defaultValue: "var(--token-7288c754-42a3-4bcb-928f-d7e01bf62ad6, rgb(0, 0, 0)) /* {\"name\":\"Black\"} */", title: "Color", type: ControlType.Color}, sUniGUsLS: {defaultValue: "Link", displayTextArea: false, title: "Text", type: ControlType.String}, T7R6_DfGi: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(FramerIp0CJH8r8, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})